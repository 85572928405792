import { useCallback } from 'react'

import { useQueryPartner } from '~/hooks-queries'
import { useQueryDorisConfig } from '~/hooks-queries'

import { IPartner } from '~/entities'

import { IUsePartner, TGetPartnerResponse } from './types'
import { configHasDeletionFlag, getTemplate, getType } from './utils'

const storageKey = '@doris:partner'

export const usePartner = (): IUsePartner => {
  const { fetch: fetchPartner } = useQueryPartner()
  const { fetch: fetchDorisConfig } = useQueryDorisConfig()

  const getPartnerStorage = useCallback((): IPartner | null => {
    const storage = localStorage.getItem(storageKey)
    return storage ? JSON.parse(storage) : null
  }, [])

  const setPartner = useCallback(({ partner }: { partner: IPartner }) => {
    localStorage.setItem(storageKey, JSON.stringify(partner))
  }, [])

  const getDorisConfig = useCallback(
    async ({ partner }: { partner: IPartner }) => {
      if (!partner.id) return null

      try {
        const config = await fetchDorisConfig(partner.id)

        return config
      } catch (error) {
        // TODO: TECHNICAL DEBT: ADD TEST TO COVER THIS SCENARIO
        return null
      }
    },
    [fetchDorisConfig],
  )

  const getPartner = useCallback((): Promise<TGetPartnerResponse> => {
    return new Promise(async (resolve, reject) => {
      const response: TGetPartnerResponse = { status: true, message: 'Success' }

      try {
        const partner = await fetchPartner()
        response.data = { ...partner }

        const dorisConfig = await getDorisConfig({ partner })
        if (dorisConfig) {
          response.data.delete_local_tryon_history = configHasDeletionFlag(dorisConfig)
        }

        resolve(response)
      } catch (error) {
        response.message = error.message
        response.status = false

        reject(response)
      }
    })
  }, [fetchPartner, getDorisConfig])

  const sendMessage = useCallback(
    ({ products }) => {
      const partner = getPartnerStorage()
      const type = getType(partner || {})

      if (!products.length || !partner || !type) return

      window.open(getTemplate({ partner, products, type }), '_blank')
    },
    [getPartnerStorage],
  )

  return { sendMessage, getPartner, setPartner }
}
