/* eslint-disable no-console */
import { ThemeProvider } from 'styled-components'

import { FC, useEffect, useState } from 'react'

import { StartButton } from '~/components/StartButton'
import { StartButtonLegacy } from '~/components/StartButtonLegacy'

import theme from '~/theme'

import { Sentry } from '~/clients/sentry'
import { dorisLog } from '~/utils/dorisLog'
import { GlobalStyles } from '~/utils/injectButton/styles'
import { verify } from '~/utils/verify'
import { IVerifyV2Response } from '~/utils/verify/types'

import { IStartButtonParams } from './types'

export const StartButtonComponent: FC<IStartButtonParams> = props => {
  const [showButton, setShowButton] = useState(false)
  const [widgetDefaultFlow, setWidgetDefaultFlow] = useState(true)
  const [verifyResponse, setVerifyResponse] = useState<IVerifyV2Response | null>(null)

  const handleOnClick = (skus: string[]) => {
    const event = new CustomEvent<{
      skus: Array<string>
      defaultFlow: boolean
      productValidated: boolean
      verifyResponse: IVerifyV2Response | null
    }>('doris-start', {
      detail: {
        skus,
        defaultFlow: widgetDefaultFlow,
        productValidated: props.validateSku,
        verifyResponse: verifyResponse,
      },
    })
    dorisLog(`Starting widget with ${skus.length ? `skus: ` + skus : 'no skus'}`)
    document.dispatchEvent(event)
  }

  useEffect(() => {
    const fetchVerify = async () => {
      if (!props.skus?.length) {
        console.error('[DORIS][INJECTBUTTON] No SKU were found or provided.')
        Sentry.captureException({
          errorName: 'SKU_NOT_FOUND',
          errorMessage: 'No SKU were found or provided.',
          filePath: 'src/utils/injectButton/components/StartButtonComponent/index.tsx',
          functionName: 'fetchVerify',
        })
        return
      }

      if (!props.apiKey) {
        console.error('[DORIS][INJECTBUTTON] No API KEY found.')
        Sentry.captureException({
          errorName: 'API_KEY_NOT_FOUND',
          errorMessage: 'No API KEY found.',
          filePath: 'src/utils/injectButton/components/StartButtonComponent/index.tsx',
          functionName: 'fetchVerify',
        })
        return
      }

      const verifyResponse = (await await verify({
        sku: props.skus[0],
        apiKey: props.apiKey,
        returnVerifyResponse: true,
      })) as { valid: boolean; response?: IVerifyV2Response }

      setVerifyResponse(verifyResponse.response || null)

      if (verifyResponse.response?.valid) {
        setWidgetDefaultFlow(!verifyResponse.response?.viton && verifyResponse.response?.size ? false : true)
      }
      if (!verifyResponse.valid) {
        console.error('[DORIS][INJECTBUTTON] The provided SKU is invalid.')
        Sentry.captureException({
          errorName: 'INVALID_SKU',
          errorMessage: 'The provided SKU is invalid.',
          filePath: 'src/utils/injectButton/components/StartButtonComponent/index.tsx',
          functionName: 'fetchVerify',
        })
      }
      setShowButton(verifyResponse.valid)
    }

    if (!props.validateSku) {
      setShowButton(true)

      return
    }

    fetchVerify()
  }, [props])

  return (
    <div className="doris-widget-start-button">
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {showButton &&
          (props.useNewTrigger ? (
            <StartButton
              {...props}
              images={props.backgroundImages}
              onClick={() => handleOnClick(props.skus || [])}
              defaultFlow={widgetDefaultFlow}
            />
          ) : (
            <StartButtonLegacy
              {...props}
              images={props.backgroundImages}
              onClick={() => handleOnClick(props.skus || [])}
            />
          ))}
      </ThemeProvider>
    </div>
  )
}
