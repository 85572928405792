export const getTryonProducts = (skus = [], products = []) => {
  const tryonProducts = {}

  skus.forEach(sku => {
    const productsData = products.filter(product => product.identifier === sku)

    if (!productsData.length) return

    const lastProduct = productsData[productsData.length - 1]

    tryonProducts[lastProduct.category.type.toLowerCase()] = lastProduct
  })

  return tryonProducts
}

export const checkLastEventTimestamp = () => {
  const lastEvent = localStorage.getItem('@doris:last-event')

  if (!lastEvent) {
    return false
  }

  const event = JSON.parse(lastEvent)
  const eventTimestamp = new Date(event.timestamp)
  const currentTime = new Date()
  const timeDifference = (currentTime.getTime() - eventTimestamp.getTime()) / (1000 * 60)

  return timeDifference > 30
}

export const deleteLocalStorageAvatars = () => {
  localStorage.removeItem('@doris:avatars')
}

export const deleteTryonHistory = () => {
  localStorage.removeItem('@doris:tryon_history')
}

export const deleteCurrentTryon = () => {
  localStorage.removeItem('@doris:current_tryon')
}
