import { AxiosClient } from '~/clients/axios'
import { Sentry } from '~/clients/sentry'
import { dorisLog } from '~/utils/dorisLog'
import { MobileUtils } from '~/utils/mobileUtils'
import Tracking from '~/utils/tracking'

import { SEND_VERIFY_EVENT_PARTNER_API_KEYS } from './constants'
import { IVerifyV2Response, TVerifyParams } from './types'

export const validateProductCompatibility = async ({ sku }: { sku: string }): Promise<IVerifyV2Response> => {
  try {
    const { data } = await AxiosClient().post<IVerifyV2Response>(
      'v3/outfit/verify',
      { identifier: sku },
      {
        timeout: 2000,
        timeoutErrorMessage: 'ERROR_TIMEOUT_WHILE_VERIFYING_SKU',
      },
    )

    return data
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error({ error })

    Sentry.captureException({
      errorName: 'ERROR_WHILE_VERIFYING_SKU',
      errorMessage: 'Failed fetching products',
      filePath: 'src/utils/verify/index.ts',
      functionName: 'validateProductCompatibility',
      payload: { idenfitier: sku },
    })

    return {
      valid: false,
      viton: false,
      size: false,
    }
  }
}

const dispatchVerifyEvent = ({
  apiKey,
  response,
  sku,
  verifyResponse,
}: {
  apiKey: string
  response: boolean
  sku: string | undefined
  verifyResponse?: IVerifyV2Response
}): void => {
  if (!SEND_VERIFY_EVENT_PARTNER_API_KEYS.includes(apiKey)) return

  const url = new URL(window.location.href)
  Tracking.logEvent('VERIFY', {
    sku,
    return: response,
    widget: true,
    url: window.location.href,
    utm_id: url.searchParams.get('utm_id'),
    utm_source: url.searchParams.get('utm_source'),
    utm_medium: url.searchParams.get('utm_medium'),
    utm_campaign: url.searchParams.get('utm_campaign'),
    utm_term: url.searchParams.get('utm_term'),
    utm_content: url.searchParams.get('utm_content'),
    device: MobileUtils.isMobile() ? 'mobile' : 'desktop',
    viton: verifyResponse?.viton,
    size: verifyResponse?.size,
  })
}

export const verify = async ({
  sku,
  apiKey,
  returnVerifyResponse,
}: TVerifyParams): Promise<boolean | { valid: boolean; response?: IVerifyV2Response }> => {
  dorisLog(`Verifying products [${sku}] using api key ${apiKey}`)

  if (!sku) {
    dispatchVerifyEvent({ apiKey, sku: undefined, response: true })
    return true
  }

  const response = await validateProductCompatibility({ sku })
  const result = response?.valid && (response?.viton || response?.size)

  dispatchVerifyEvent({ apiKey, sku, response: result, verifyResponse: response })

  if (returnVerifyResponse) {
    return { valid: result, response: response }
  }
  return result
}
